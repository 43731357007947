<script>
import { filterArray, getArraySize } from '@emobg/web-utils';
import { employeeList, getEmployeeList } from '@/stores/Company/CompanyMapper';

import ALERT_TYPES from '@/components/Alert/alertTypes';
import AlertComponent from '@/components/Alert/AlertComponent';

export default {
  name: 'AdditionalDriversComponent',

  components: {
    AlertComponent,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasInvalidDrivers: {
      type: Boolean,
      default: false,
    },
    selectedDrivers: {
      type: Array,
      default: () => [],
    },
    bookingOwnerUuid: {
      type: String,
      default: '',
    },
  },
  computed: {
    employeeList,
    displayedDrivers() {
      const employees = filterArray(
        this.employeeList,
        (employee) => (!this.selectedDrivers.includes(employee.email) && employee.userUuid !== this.bookingOwnerUuid),
      );
      return employees || [];
    },
    selectedDriversEmails() {
      return getArraySize(this.selectedDrivers) ? this.selectedDrivers.join(', ') : '';
    },
  },
  async created() {
    this.ALERT_TYPES = ALERT_TYPES;
    await this.getEmployeeList();
  },
  methods: {
    getEmployeeList,
  },
};
</script>

<template>
  <div
    class="AdditionalDriversComponent"
    data-test-id="additional_driver-component"
  >
    <div class="pb-3">
      {{ $t('carsharing_personal.additional_drivers.select_label') }}
    </div>
    <div class="mb-2">
      <ui-select
        v-if="displayedDrivers"
        :label="$t('carsharing_personal.additional_drivers.select_info')"
        :placeholder="$t('carsharing_personal.additional_drivers.select_placeholder')"
        :disabled="disabled"
        :value="selectedDriversEmails"
        :options.prop="displayedDrivers.map(item => ({ label: item.email, value: item.email }))"
        :searchbox="{ threshold: -1 }"
        data-test-id="select-additional_drivers"
        name="additional-drivers"
        @selectoption="({ detail }) => $emit('add-driver', detail)"
      />
      <ui-skeleton v-else />
    </div>
    <div
      v-if="hasInvalidDrivers"
      data-test-id="error-message"
      class="input-error-message emobg-font-x-small mb-3"
    >
      {{ $t('carsharing_personal.additional_drivers.invalid_driver_email') }}
    </div>
    <AlertComponent
      :type="ALERT_TYPES.warning"
      data-test-id="alert-additional-drivers"
    >
      {{ $t('carsharing_personal.additional_drivers.info_text') }}
    </AlertComponent>
  </div>
</template>
