<template>
  <div
    :class="{
      'flex-column': isVertical
    }"
    class="d-flex"
  >
    <div
      v-if="!isStartDisabled"
      class="startInputContainer"
    >
      <label class="emobg-font-weight-bold">
        <span>{{ $t('common.booking.start') }}</span>
        <span v-if="!newStart.isSame(start)">
          -
          <span class="emobg-color-success"> {{ $t('modal.edit_booking_time.new_time') }}</span>
        </span>
      </label>

      <ui-datetimepicker
        :placeholder="$t('common.booking.start')"
        :disabled="isStartDisabled"
        :date.prop="start"
        :interval="intervalTimeUnit"
        :valid-date-range.prop="startValidRange"
        :class="{
          'mb-2': isVertical,
          'mr-4': !isVertical
        }"
        :date-format-key="dateFormatKey"
        data-test-id="start-datetimepicker"
        @datechanged="({ detail }) => $emit('update:start', detail)"
      />
      <div
        v-if="textStart && !isBookingRequest"
        data-test-id="booking-availability-info"
        class="p-2 emobg-background-color-ground-lightest"
      >
        <span class="emobg-color-ink-light">{{ $t('modal.edit_booking_time.maximum_start_text') }}</span>
        <br>
        <b class="emobg-font-weight-bold">{{ textStart }}</b>
      </div>
    </div>
    <label class="mx-1 mt-3 emobg-font-weight-bold">
      <span>{{ $t('common.booking.end') }}</span>
      <span v-if="!newEnd.isSame(end)">
        -
        <span class="emobg-color-success"> {{ $t('modal.edit_booking_time.new_time') }}</span>
      </span>
    </label>
    <ui-datetimepicker
      :placeholder="$t('common.booking.end')"
      :disabled="isEndDisabled"
      :date.prop="end"
      :interval="intervalTimeUnit"
      :valid-date-range.prop="endValidRange"
      :date-format-key="dateFormatKey"
      data-test-id="end-datetimepicker"
      class="mb-2"
      @datechanged="({ detail }) => $emit('update:end', detail)"
    />
    <div
      v-if="textEnd && !isBookingRequest"
      data-test-id="booking-availability-info"
      class="p-2 emobg-background-color-ground-lightest"
    >
      <span class="emobg-color-ink-light">{{ $t('modal.edit_booking_time.maximum_end_text') }}</span>
      <br>
      <b class="emobg-font-weight-bold">{{ textEnd }}</b>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { DATE_FORMAT } from '@emobg/web-utils';
import { DATE_UNITS } from '@/constants/dates';

export default {
  name: 'BookingDatetimepicker',
  props: {
    start: {
      type: Object,
      default: () => moment(),
    },
    end: {
      type: Object,
      default: () => ({}),
    },
    intervalTimeUnit: {
      type: Number,
      required: true,
    },
    minimumBookingDuration: {
      type: Number,
      required: true,
    },
    maximumBookingDuration: {
      type: Number,
      required: true,
    },
    maximumFutureBookingTime: {
      type: Number,
      required: true,
    },
    pastLimitDate: {
      type: Object,
      default: () => ({}),
    },
    futureLimitDate: {
      type: Object,
      default: () => ({}),
    },
    isStartDisabled: {
      type: Boolean,
      default: false,
    },
    isEndDisabled: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    dateFormatKey: {
      type: String,
      default: DATE_FORMAT.isoDateTime,
    },
    allowedPastPeriod: {
      type: Object,
      default: () => ({}),
    },
    isBookingRequest: {
      type: Boolean,
      default: false,
    },
    textStart: {
      type: String,
      default: '',
    },
    textEnd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newStart: null,
      newEnd: null,
    };
  },
  computed: {
    startValidRange() {
      const durationLimit = moment().add(this.maximumFutureBookingTime, DATE_UNITS.minutes);
      const defaultDate = isEmpty(this.allowedPastPeriod) ? moment() : this.allowedPastPeriod;
      const startLimit = isEmpty(this.pastLimitDate) || this.pastLimitDate.isSameOrBefore(defaultDate)
        ? defaultDate
        : this.pastLimitDate;

      const endLimit = !isEmpty(this.futureLimitDate) && this.futureLimitDate.isSameOrBefore(durationLimit)
        ? this.futureLimitDate
        : durationLimit;

      return {
        start: startLimit,
        end: endLimit,
      };
    },
    endValidRange() {
      const durationLimit = moment(this.start).add(this.maximumBookingDuration, DATE_UNITS.minutes);
      const endLimit = !isEmpty(this.futureLimitDate) && this.futureLimitDate.isSameOrBefore(durationLimit)
        ? this.futureLimitDate
        : durationLimit;

      return {
        start: moment(this.start).add(this.minimumBookingDuration, DATE_UNITS.minutes),
        end: endLimit,
      };
    },
  },
  watch: {
    start(_newStart, oldStart) {
      const currentBookingDuration = this.end.diff(oldStart, DATE_UNITS.minutes);
      const isBookingDurationValid = moment(currentBookingDuration).isValid();

      const isInRange = this.end.isBetween(this.endValidRange.start, this.endValidRange.end);

      if (!isInRange) {
        const datesGapMinutes = (this.end.isSameOrBefore(this.start) || !isBookingDurationValid)
          ? this.minimumBookingDuration
          : currentBookingDuration;

        const endDateOffset = moment(this.start).add(datesGapMinutes, DATE_UNITS.minutes);
        const newEnd = endDateOffset.isSameOrBefore(this.endValidRange.end)
          ? endDateOffset
          : this.endValidRange.end;

        this.$emit('update:end', newEnd);
      }
    },
  },
  created() {
    this.newStart = this.start;
    this.newEnd = this.end;
  },
};
</script>
